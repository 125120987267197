<template>
  <div class="relative">
    <div class="pb-4 pt-32 px-8">
      <h1
        class="flex justify-left text-3xl flex font-bold leading-small mb-4 text-left sm:text-4xxl sm:justify-center"
      >
        Our Process
      </h1>
      <div>
        <h3
          class="sm:block hidden sm:text-center text-left text-base leading-loose text-grey-text xl:w-full lg:max-w-4xl mx-auto font-normal"
        >
          Find out more details about our approach when it comes to<br />
          project estimation &amp; planning and preparation for the development
          phase.
        </h3>
        <h3
          class="sm:hidden block sm:text-center text-left text-base leading-loose text-grey-text xl:w-full lg:max-w-4xl mx-auto font-normal"
        >
          Find out more details about our approach when it comes to project
          estimation &amp; planning and preparation for the development phase.
        </h3>
        <div
          class="flex flex-wrap mt-3 sm:justify-center justify-start items-center sm:w-full"
        ></div>
      </div>
    </div>
    <div class="page__background absolute"></div>
    <div class="md:mx-0 mx-8">
      <section class="section__one">
        <div class="section__one-img">
          <img
            src="img/process/process-img1.svg"
            alt=""
          />
        </div>
        <div class="section__one-content">
          <div class="oval bg-primary">1</div>
          <div
            class="text-3xxl font-bold leading-small text-process-page w-48 my-3"
          >
            Request an estimation
          </div>
          <p class="leading-lg text-grey-text">
            You contact us via email or through the contact/ request estimation
            pages. In order to ensure a smooth process and communication, it’s
            best to describe your needs and project clearly or provide a
            detailed requirements description/document.
          </p>
        </div>
      </section>
      <section class="section__two">
        <div class="flex flex-col max-w-small mt-5">
          <span class="bg-process-page oval">2</span>
          <div
            class="text-3xxl font-bold leading-small text-process-page w-64 my-4"
          >
            Clarify general project details
          </div>
          <p class="leading-lg text-grey-text pb-3">
            We respond back, usually via email with a short intro, more
            information about us, whether we’ve had experience with similar
            projects, tech stack we’re using and any questions you might have.
          </p>
          <p class="leading-lg text-grey-text">
            Usually at this step we see whether we’re a good fit. We might ask
            some extra questions to better understand your idea and business.
          </p>
        </div>
        <div class="md:ml-24 ml-0">
          <img
            src="img/process/process-img2.png"
            alt=""
          />
        </div>
      </section>
      <section class="section__three">
        <div class="section__three-img">
          <img
            src="img/process/process-img3.png"
            alt=""
          />
        </div>
        <div class="flex flex-col max-w-small mt-10">
          <span class="oval bg-primary">3</span>
          <div
            class="text-3xxl font-bold leading-small text-process-page w-48 my-3"
          >
            Kick off meeting
          </div>
          <p class="leading-lg text-grey-text">
            We establish a formal video conference together. The purpose of this
            meeting is to get to know each other, discuss the big picture of the
            project and further clarify details about the project.
          </p>
        </div>
      </section>
      <section class="section__four">
        <div class="flex flex-col max-w-small -mt-3">
          <span class="bg-process-page oval">4</span>
          <div
            class="text-3xxl font-bold leading-small text-process-page w-48 my-4"
          >
            Project estimation
          </div>
          <p class="leading-lg text-grey-text pb-3">
            At this step we try to roughly estimate the project. Depending on
            how clear the requirements are or how big the project is, we might
            estimate an MVP (minimum viable version) of it first.
          </p>
          <p class="leading-lg text-grey-text">
            After this phase you get an idea about the cost, duration and any
            high level technical implications of the project. The estimation
            includes the worst, best and average scenarios.
          </p>
        </div>
        <div class="section__four-img">
          <img
            src="img/process/process-img4.svg"
            alt=""
          />
        </div>
      </section>
      <section class="section__five">
        <div class="md:mr-8 mr-0">
          <img
            src="img/process/process-img5.svg"
            alt=""
          />
        </div>
        <div class="flex flex-col max-w-small -mt-5">
          <span class="oval bg-primary">5</span>
          <div
            class="text-3xxl font-bold leading-small text-process-page sm:w-86 w-64 my-3"
          >
            Planning the project implementation
          </div>
          <div class="leading-lg text-grey-text pb-3">
            In this phase we dive deep into technical aspects, break down the
            project into core features. If you have the time and availability,
            we’re more than happy to meet face to face at this point and plan
            everything together.
          </div>
          <p class="leading-lg text-grey-text">
            At this point we also sign legal documents and better plan the
            budget, timeline and expectations about the project.
          </p>
        </div>
      </section>
      <section class="section__six">
        <div class="flex flex-col max-w-small -mt-3">
          <span class="bg-process-page oval">6</span>
          <div
            class="text-3xxl font-bold leading-small sm:w-90 text-process-page sm:w-86 w-64 my-4"
          >
            Kick off the development process
          </div>
          <p class="leading-lg text-grey-text pb-3">
            Once we have everything planned, we start the development phase.
            This phase includes short iterations called sprints where we develop
            certain features of the project.
          </p>
          <p class="leading-lg text-grey-text">
            You can find more details about these, technologies we use and how
            we approach technical challenges in the “Internal Process” tab.
          </p>
        </div>
        <div class="md:ml-8 ml-0">
          <img
            src="img/process/process-img6.png"
            alt=""
          />
        </div>
      </section>
    </div>
    <Estimate></Estimate>
  </div>
</template>

<script>
import Estimate from "@/views/subComponents/Estimate.vue";

export default {
  components: {
    Estimate,
  }
};
</script>

<style lang="css" scoped></style>
